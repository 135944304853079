import React, { Fragment, useState, useEffect } from 'react'
import Slider from '../common/Slider/Slider';
import { getCmsDataBySection } from "../../common_js/homepage"
import { getClassName, getSentenceCase, handle_CTA_redirection, get_CTA_redirection } from '@/common_js/common';
import Image from 'next/image';

const OffersForyou = (props) => {
    const { LoadingComponent, iscrawler, homePageCmsData, isMobile } = props
    const [CmsData, setCmsData] = useState(homePageCmsData || {})
    const [isLoading, setisLoading] = useState(props.isLoading || !homePageCmsData?.data?.length || false)

    useEffect(() => {
        async function fetchData() {
            let data = await getCmsDataBySection("PopularAmongsTravellers")
            setCmsData(data?.PopularAmongsTravellers || {})
            setisLoading(false)
        }
        if (!CmsData?.data?.length)
            fetchData()
        else if (isLoading)
            setisLoading(false)
    }, [])

    return (
        <Fragment>
            {isLoading ?
                LoadingComponent :
                <Fragment>
                    {CmsData?.data?.length ?
                        <div className={getClassName(props.homestyle['offers-for-you-container'])}>
                            <div className={getClassName(props.homestyle['popular_amongst_traveller_container'])}>
                                <h2 className={getClassName('homepage-title')}>{CmsData.title || ""}</h2>
                                <div className={getClassName(props.homestyle['popular_amongst_traveller_slider'])}>

                                    <Slider homestyle={props.homestyle} sliderData={CmsData.data || []} maxCount={CmsData.data.length} no_of_cards={isMobile ? 1.25 : 4} arrows={false} spacing={17} ssr={true}>
                                        {CmsData.data.length
                                            ? CmsData.data.map((ele, id) => {
                                                let link = get_CTA_redirection(ele.CTA.type, ele.CTA.data);

                                                return link ? (
                                                    <a href={link} target="_blank" rel="noopener noreferrer" key={id}>
                                                        <div className={[props.homestyle['popular_amongst_traveller_container_img']].join(" ")}>
                                                            <Image
                                                                alt={ele.alt}
                                                                className={[props.homestyle['popular_amongst_traveller_icon']].join(" ")}
                                                                src={ele.image}
                                                                width={277}
                                                                height={205}
                                                                sizes={'100%'}
                                                                style={{ width: '100%', height: 'auto', maxHeight: '100%' }}
                                                                fetchPriority={"high"}
                                                                loading={"eager"}
                                                            />
                                                            <div className={[props.homestyle['popular_amongst_traveller_container_img_text']].join(" ")}>
                                                                <p>{ele.title}</p>
                                                                <span>{ele.sub_title}</span>
                                                            </div>
                                                        </div>
                                                    </a>
                                                ) : (
                                                    <div key={id} className={[props.homestyle['popular_amongst_traveller_container_img']].join(" ")}>
                                                        <Image
                                                            alt={ele.alt}
                                                            className={[props.homestyle['popular_amongst_traveller_icon']].join(" ")}
                                                            src={ele.image}
                                                            width={277}
                                                            height={205}
                                                            sizes={'100%'}
                                                            style={{ width: '100%', height: 'auto', maxHeight: '100%' }}
                                                            fetchPriority={"high"}
                                                            loading={"eager"}
                                                        />
                                                        <div className={[props.homestyle['popular_amongst_traveller_container_img_text']].join(" ")}>
                                                            <p>{ele.title}</p>
                                                            <span>{ele.sub_title}</span>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                            : null}

                                    </Slider>
                                </div>
                            </div >
                        </div>
                        : null}
                </Fragment>
            }
        </Fragment>

    )
}

export default OffersForyou